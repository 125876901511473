<template>
    <section v-if="isActiveImpersonateUser" class="alert-wrapper">
        <div class="alert">
            <h4>
                <PIcon name="info-circle-outline" class="mb-2" />
                <span> Вы просматриваете страницу в режиме профиля поставщика </span>
            </h4>
            <PButton small @click="switchToActiveProfile">Вернуться в свой профиль</PButton>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import PButton from '@/shared/ui/PButton/PButton.vue';
import PIcon from '@/shared/ui/PIcon/PIcon.vue';
import { notifyError } from '@/shared/model/utils/showNotify';
import { getSavedQueryParamsToStorage, StorageQueryParams } from '@/utils/saveQueryParams';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/shared/model/store/UserStore';
import { switchImpersonateProfileToActiveProfile } from '@/processes/Auth';

const router = useRouter();
const userStore = useUserStore();

const isActiveImpersonateUser = computed<boolean>(() => userStore.isActiveImpersonateUser);

async function switchToActiveProfile() {
    try {
        await switchImpersonateProfileToActiveProfile();
        /**
         * Костыль: На случай, если пользователь уже находился на странице Сотрудники компании (usersTable).
         * Дополнительно обновим состояние грида сотрудников, переключая route с profile на usersTable
         */
        await router.push({ name: 'profile' });
        const query = getSavedQueryParamsToStorage(StorageQueryParams.USER_TABLE_QUERY);
        await router.replace({ name: 'usersTable', query });
    } catch (error) {
        notifyError('Произошла ошибка при обработке данных смены профиля пользователя');
    }
}
</script>

<style scoped lang="scss">
.alert-wrapper {
    position: fixed;
    z-index: 80;
    bottom: 6rem;
    width: 100%;
    padding-inline: 5.6rem;
}

.layout:has(.sidebar) ~ .alert-wrapper {
    padding-inline: 14rem 5.6rem;
}

.alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: var(--navy-blue-light);
    border-radius: var(--border-radius-8);
    color: var(--white);
    box-shadow: var(--shadow-card-dark);
}
</style>
